<template src="./pageSettings.html"></template>

<script>
import draggable from 'vuedraggable'
import Http from '@/shared/http-config'
import {BCol} from "bootstrap-vue/src/components/layout/col";
import CmsNavRecursion from '../nav-recursion/NavRecursion';
import CmsStore from '../CmsStore'
import RegisterStoreModule from '@/shared/registerStoreModule'
import { mapFields } from 'vuex-map-fields';

export default {
  name: "CmsPageSettings",
  components: {BCol, draggable},
  mixins: [CmsNavRecursion, RegisterStoreModule],
  data: function () {
    return {
      all_meta_tags: [],
      meta_tags: [],
      order: [],
    }
  },
  created: function(){
    let self = this;
    self.registerStoreModule('cms', CmsStore);

    Http.request('get', '/website/meta')
    .then((meta_tags) => {
      meta_tags.data.forEach(function (meta_tag) {
        self.all_meta_tags.push({
          text: meta_tag.description,
          value: meta_tag.id
        });
      });
    });
  },
  computed: {
    user () {
      return this.$store.getters.getUser;
    },
    ...mapFields('cms', [
      'navigation',
      'current_nav.id',
      'current_nav.owned',
      'current_nav.title',
      'current_nav.alias',
      'current_nav.parent_id',
      'current_nav.online',
      'current_nav.content',
      'current_nav.show_link',
      'current_nav.start',
      'current_nav.is_external_link',
      'current_nav.allow_user_online',
      'current_nav.allow_user_content',
      'current_nav.allow_user_navTitle',
    ]),
  },
  methods: {
    addMetaTag: function () {
      this.meta_tags.push({
        id: null,
        value: ''
      });
    },
    removeMetaTag: function (index) {
      this.$delete(this.meta_tags, index);
    },
    changeOrderItems(){
      let self = this;
      self.order = this.navItemsOnSameLevel(self.parent_id, self.navigation);
      if(self.order.findIndex(nav => nav.value === self.id) === -1) {
        self.order.push({
          text: 'Neuer Menüpunkt',
          owned: true
        });
      }
    },
    navItemsOnSameLevel: function (parent_id, navigation) {
      var nav = [];
      nav = this.navRecursion(nav, navigation, 0, parent_id);
      return nav;
    },
  }
}
</script>

<style scoped>
.custom-checkbox {
  margin-bottom: 10px;
}
</style>
